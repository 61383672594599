import React, { useEffect } from "react";
import { Section } from "../../layout/Section";
import { Background } from "components/atoms/Background";
import { FooterCopyright } from "./FooterCopyright";
import logo from "assets/svg/logo.svg";
import appStoreBadge from "assets/images/app-store-badge.svg";
import googlePlayBadge from "assets/images/google-play-badge.svg";
import googleSafePage from "assets/images/selo-google-safe-browsing.png";

const Footer: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js';
    script.async = true;
    script.id = 'ra-embed-verified-seal';
    script.setAttribute('data-id', 'SFVQV1BJYVNPWUpYNzY5ZDp2aW5jaS12aWRhLWUtcHJldmlkZW5jaWEtcy1h');
    script.setAttribute('data-target', 'ra-verified-seal');
    script.setAttribute('data-model', '1');
    const sealElement = document.getElementById('ra-verified-seal');
    if (sealElement) {
      sealElement.appendChild(script);
    }

    return () => {
      if (sealElement) {
        sealElement.removeChild(script);
      }
    };
  }, []);

  return (
    <Background color="bg-[#052329]">
      <footer className="md:px-10">
        <Section yPadding="py-0">
          <div className="grid md:grid-cols-[2fr_220px] lg:grid-cols-[2fr_220px_256px_auto] gap-x-20 gap-y-10 py-16">
            <div className="flex flex-col">
              <img src={logo} alt="" width={168} />
              <p className="text-base text-neutralTextLightIntense max-w-[346px] mt-10 mb-3">
                Av. Brigadeiro Faria Lima, 2.277 - 14º andar Jardim Paulistano -
                São Paulo SP
              </p>
              {/* Selo verificado */}
              <div className="inline-flex gap-3">
                <div id="ra-verified-seal" ></div>
                <a
                  href="https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fmiovincipartners.com%2F"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={googleSafePage} alt="Google Safe" style={{ width: '165px' }} />
                </a>

              </div>
            </div>

            <div className="flex flex-col gap-2">
              <h4 className="mb-2 text-lg font-medium text-white">
                Fale com a gente
              </h4>

              <p className="text-neutralTextLightIntense">
                sac-mio@vincipartners.com
              </p>
              <p className="text-neutralTextLightIntense">0800 0003 431</p>
              <p className="text-neutralTextLightIntense">
                Atendimento das 9h às 18h{" "}
                <span className="text-xs">(dias úteis)</span>
              </p>
            </div>

            <div className="flex flex-col gap-2">
              <h4 className="mb-2 text-lg font-medium text-white">Ouvidoria</h4>

              <p className="text-neutralTextLightIntense">
                ouvidoria@vincipartners.com
              </p>
              <p className="text-neutralTextLightIntense">0800 0003 441</p>
              <p className="text-neutralTextLightIntense">
                Atendimento das 9h às 18h{" "}
                <span className="text-xs">(dias úteis)</span>
              </p>
            </div>

            <div className="flex flex-col gap-4">
              <h4 className="text-lg font-medium text-white ">Baixe o app</h4>

              <a
                href="https://apps.apple.com/br/app/mio-vinci-partners/id6444894937"
                target="_blank"
                rel="noreferrer"
              >
                <img src={appStoreBadge} alt="App Store" style={{ width: '150px' }} />
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=br.com.vinciprev"
                target="_blank"
                rel="noreferrer"
              >
                <img src={googlePlayBadge} alt="Google Play" style={{ width: '150px' }} />
              </a>
            </div>
          </div>

          <div className="w-full lg:text-left text-left">
            <span className="text-sm text-neutralTextLightIntense font-medium leading-[18px] tracking-wide lg:text-xs">
              A aprovação do plano pela SUSEP não implica, por parte da Autarquia, incentivo ou recomendação a sua comercialização. Possibilidade de opção pelo critério de tributação por alíquotas decrescentes. Rentabilidade passada não representa garantia de rentabilidade futura. A rentabilidade divulgada não é líquida de impostos. Regulamentos dos Fundos de Investimento em que a Vinci Vida e Previdência investe podem ser consultados no portal da CVM, na rede mundial de computadores. Fundos de Investimento não contam com garantia da Vinci, de qualquer de suas afiliadas, do administrador, de qualquer mecanismo de seguro ou, ainda, do Fundo Garantidor de Créditos (FGC). Este site usa dados pessoais de acordo com a Política de Privacidade do Grupo Vinci (<a href="https://www.vincipartners.com/Home/privacidade" target="_blank" className="text-neutralTextLightIntense" rel="noreferrer">Acesse aqui</a>), ao acessá-lo, o usuário declara estar ciente e de acordo com as condições da Política. Canal Ouvidoria 0800 0003 441 e ouvidoria@vincipartners.com. SAC 0800 0003 431 e sac-mio@vincipartners.com. Para mais informações, consulte o Aviso de Privacidade (<a href="https://www.vincipartners.com/Home/privacidade" target="_blank" className="text-neutralTextLightIntense" rel="noreferrer">Acesse aqui</a>). Vinci Vida e Previdência S/A - CNPJ: 46.938.918/0001-87. Processos SUSEP PGBL: 15414.600122/2023-19 e 15414.634737/2023-31 e VGBL: 15414.600123/2023-55 e 15414.634735/2023-41. Para contato com o encarregado de proteção de dados (DPO) (<a href="https://www.vincipartners.com/home/protecaodados" target="_blank" className="text-neutralTextLightIntense" rel="noreferrer">Acesse aqui</a>).
            </span>
          </div>
          <FooterCopyright />

        </Section>
      </footer>
    </Background>
  );
};

export { Footer };
